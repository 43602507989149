import React from "react"
import { graphql } from "gatsby"

// Components
import Layout from "../layout/layout"
import SEO from "../layout/seo"
import Header from "../layout/header"
import Gallery from "../components/gallery/gallery"

const GalleryPage = ({ data }) => {
  const images = data.graphcms.gallery.images.map(el => ({
    id: el.id,
    original: el.original?.childImageSharp.resize.src,
    thumbnail: el.thumbnail?.childImageSharp.fluid,
    width: el.original?.childImageSharp.resize.width,
    height: el.original?.childImageSharp.resize.height,
    alt: el.alt
  }))

  const slides = data.graphcms.header.slides?.map(el => ({
    title: el.title,
    subtitle: el.subtitle,
    image: el.image?.imageFile?.childImageSharp.fluid,
    imageAlt: el.image?.alt ?? el.title,
    backgroundOpacity: el.darken / 100 ?? 0.45,
  }))

  const title = data.graphcms?.gallery?.name
  const description = data.graphcms?.gallery?.description?.html

  return (
    <Layout>
      <SEO title="Bramy i furtki | Produkcja i montaż | Kępno, Mroczeń" description="Zajmujemy się produkcją, sprzedażą oraz montażem nowoczesnych bram i furtek. Kępno - Mroczeń" />
      <Header darkBackgroundOpacity={0.05} slides={slides} />
      <Gallery images={images} title={title} description={description}/>
    </Layout>
  )
}

export const query = graphql`
  query {
    test: file(relativePath: { eq: "test.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    graphcms {
      header(where: { id: "cklgmzgqokv5m0a19983tk3ue" }) {
        slides {
          title
          subtitle
          darken
          image {
            url
            alt
            imageFile {
              childImageSharp {
                fluid(
                  maxWidth: 2560
                  srcSetBreakpoints: [1920, 1440, 1200, 1024]
                  quality: 90
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }

      gallery(where: { id: "cklh32if4oo5w0a197w9dzi9t" }) {
        name
        description {
          html
        }
        images {
          id
          url
          width
          height
          alt
          thumbnail: imageFile {
            childImageSharp {
              fluid(maxWidth: 700) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          original: imageFile {
            childImageSharp {
              resize(width: 2560) {
                src
                width
                height
              }
            }
          }
        }
      }
    }
  }
`

export default GalleryPage
